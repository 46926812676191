import { clean_string } from "@/utils/global";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";

interface SkillsGroup {
  skill_group: string;
  value: string;
  class: string;
  class_v2: string;
  active_class: string;
  active_class_v2: string;
  icon: string;
  color: string;
  transform_source: (icon: HTMLElement) => HTMLElement;
}
export const skills_group: SkillsGroup[] = [
  {
    skill_group: "Leadership Skills",
    value: "Leadership and Management",
    class: "leadership-management",
    class_v2: "leadership-management-v2",
    active_class: "active",
    active_class_v2: "active-v2",
    icon: require("@/assets/icons/bold/personalcard.svg"),
    color: "#FF4066",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#FF4066");
      return icon;
    }
  },
  {
    skill_group: "Communication/Soft Skills",
    value: "Soft skills",
    class: "soft-skills",
    class_v2: "soft-skills-v2",
    active_class: "active",
    active_class_v2: "active-v2",
    icon: require("@/assets/icons/bold/simcard.svg"),
    color: "#FFB815",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#FFB815");
      return icon;
    }
  },
  {
    skill_group: "IT/Software Skills",
    value: "IT and Software",
    class: "it-software",
    class_v2: "it-software-v2",
    active_class: "active",
    active_class_v2: "active-v2",
    icon: require("@/assets/icons/bold/recovery-convert.svg"),
    color: "#33BFFF",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#33BFFF");
      return icon;
    }
  },

  {
    skill_group: "Domain Specific Skills",
    value: "Hard skills",
    class: "domain-specific",
    class_v2: "domain-specific-v2",
    active_class: "active",
    active_class_v2: "active-v2",
    icon: require("@/assets/icons/bold/ruler&pen.svg"),
    color: "#FF8473",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#FF8473");
      return icon;
    }
  },
  {
    skill_group: "Green Skills",
    value: "Green Skills",
    class: "green-skills",
    class_v2: "green-skills-v2",
    active_class: "active",
    active_class_v2: "active-v2",
    icon: require("@/assets/icons/bold/triangle.svg"),
    color: "#3CE17E",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#3CE17E");
      return icon;
    }
  }
];

/**
 * Return skill group class names
 * @param skill_group
 * @param active_skill_group
 * @param {string} version => v1 for old skill group styling & v2 for new
 * @return string => class name
 */
export function extract_skill_group_styling(
  skill_group: string,
  active_skill_group: string,
  version = "v1"
): string {
  skill_group = clean_string(skill_group);
  let tmp = skill_group;
  if (skill_group === "soft") tmp = "soft skills";
  if (skill_group === "hardware" || skill_group === "hard") tmp = "hard skills";
  if (skill_group === "green") tmp = "green skills";
  const obj = skills_group.find(
    (val: SkillsGroup) => clean_string(val.value) === tmp
  );
  if (!obj) return "";
  if (clean_string(active_skill_group) === tmp)
    return version === "v1"
      ? `${obj.active_class} ${obj.class}`
      : `${obj.active_class_v2} ${obj.class_v2}`;
  else return version === "v1" ? obj.class : obj.class_v2;
}

export function filter_skills_based_on_group(
  skills: ExtractedSkillsWithGroup.Skills[],
  active_skill_group: string
): ExtractedSkillsWithGroup.Skills[] {
  return skills.filter((skill) => {
    const skill_group = clean_string(skill.group);
    let tmp = skill_group;
    if (skill_group === "soft") tmp = "soft skills";
    if (skill_group === "hardware" || skill_group === "hard")
      tmp = "hard skills";
    if (skill_group === "green") tmp = "green skills";
    if (clean_string(active_skill_group) === tmp) return skill;
  });
}

export function get_skill_group_name(group: string): string {
  const skill_group = clean_string(group);
  let tmp = skill_group;
  if (skill_group === "soft") tmp = "soft skills";
  if (skill_group === "hardware" || skill_group === "hard") tmp = "hard skills";
  if (skill_group === "green") tmp = "green skills";
  const obj = skills_group.find(
    (val: SkillsGroup) => clean_string(val.value) === tmp
  );

  return obj ? obj.skill_group : "Skills";
}
/**
 * Function to return skills group
 * @return SkillsGroup[]
 */
export function get_skills_group(): SkillsGroup[] {
  return skills_group;
}
