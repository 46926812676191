import { render, staticRenderFns } from "./DataNotFound.vue?vue&type=template&id=6e2de64c&scoped=true&"
import script from "./DataNotFound.vue?vue&type=script&lang=ts&"
export * from "./DataNotFound.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e2de64c",
  null
  
)

export default component.exports